export enum NavigationRoute {
  LOGIN = "login",
  CHANGE_PASSWORD = "change-password",
  CHANGE_LANGUAGE = "change-language",
  LIST = "list",
  UPLOAD = "upload",
  ANALYSIS_ECG = "analysis/ecg",
  ANALYSIS_BLOOD_PRESSURE = "analysis/blood-pressure",
  ERRORS = "errors",
  ACTIONS = "actions",
  USER_LIST = "users",
  BACKGROUND_INFORMATION = "background-information",
  ADD_USERS = "add-users",
  INITIATE_MEASUREMENT = "initiate-measurement",
  HOME = "",
  ORDER_MEASUREMENT = "order-measurement",
  ORDER_HOME_MEASUREMENT = "order-home-measurement",
  PRINT_ORDER_FORM = "print-order-form",
  HOSPITALS = "hospitals",
  EDIT_HOSPITAL = "edit-hospital",
  PRINT_FORM = "print-form",
  EFORM_LANDING_PAGE = "",
  EFORM_FORM_PAGE = "eform-form",
  MAIL_DEVICE = "mail-device",
  FORGOT_PASSWORD = "forgot-password",
}
