import {MeasurementType} from "../measurement-models";
import {LocalizationStrings} from "../../i18n/i18n-model";
import {ReleaseToggleState} from "../../tools/release-toggles/release-toggle-state";

const homeMeasurementInstructionsByType: Map<MeasurementType, keyof LocalizationStrings> = new Map([
  ["SLEEP", "sleepHomeMeasurementInstructions"],
  ["HOLTER_ECG", "holterHomeMeasurementInstructions"],
  ["SYMPTOM_HOLTER", "symptomHolterHomeMeasurementInstructions"],
  ["BLOOD_PRESSURE", "bloodPressureHomeMeasurementInstructions"],
  ["ECG_ATRIAL_FIBRILLATION", "atrialFibrillationHomeMeasurementInstructions"],
]);

const homeMeasurementInstructionsWithLinkByType: Map<MeasurementType, keyof LocalizationStrings> = new Map([
  ["SLEEP", "sleepHomeMeasurementInstructionsWithLink"],
  ["HOLTER_ECG", "holterHomeMeasurementInstructionsWithLink"],
  ["SYMPTOM_HOLTER", "symptomHolterHomeMeasurementInstructionsWithLink"],
  ["BLOOD_PRESSURE", "bloodPressureHomeMeasurementInstructionsWithLink"],
  ["ECG_ATRIAL_FIBRILLATION", "atrialFibrillationHomeMeasurementInstructionsWithLink"],
]);

export function selectHomeMeasurementInstructionsLocalizationKey(type: MeasurementType): keyof LocalizationStrings {
  return ReleaseToggleState.getInstance().isReleased("E-FORM_CODE_IN_URL_397")
    ? homeMeasurementInstructionsWithLinkByType.get(type)!
    : homeMeasurementInstructionsByType.get(type)!;
}
