<div class="container row" data-testid="forgot-password-page">
  <div class="card col-md-8 mx-auto">

    <form class="card-body">
      <form-field [config]="formConfig.username"></form-field>

      <action-button
        buttonClass="btn btn-primary d-block w-50 mx-auto"
        buttonLabel="forgotPasswordFormButtonResetLabel"
        actionInProgressLabel="forgotPasswordFormButtonInProgressLabel"
        [actionInProgress]="resetInProgress"
        (buttonClick)="handleResetClick()"
        [testId]="'reset-password-button'"
      >
      </action-button>

    </form>
  </div>
</div>
