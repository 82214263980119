import {defaultEnvironment, Environment} from "./environment-model";

export const environment: Environment = {
  ...defaultEnvironment,
  production: true,
  language: "en",
  defaultLanguage: "en",
  useReleaseToggles: true,
  eforms: true,
  homeHolter: false,
  recaptchaSiteKey: "6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI",
  version: 2948,
  baseUrl: "https://m3sys.herokuapp.com",
};
