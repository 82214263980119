import {Component, OnInit} from "@angular/core";
import {emptyUserCredentialsFormModel, UserCredentialsFormModel} from "./login-form-model";
import {Notifications, NotificationType} from "../../tools/notifications/notifications";
import {RouteNavigator} from "../../tools/navigation/route-navigator.service";
import {AuthenticationService} from "../authentication/authentication.service";
import {NavigationRoute} from "../../tools/navigation/navigation-route";
import {AuthenticatedUser} from "../authentication/authenticated-user";
import {ErrorCode, ErrorResponse, singleCodeFromResponse} from "../../tools/error-response";
import {localizationKey} from "../../i18n/i18n-model";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
})
export class LoginComponent implements OnInit {
  userCredentials: UserCredentialsFormModel = emptyUserCredentialsFormModel();

  constructor(
    private readonly authenticationService: AuthenticationService,
    private readonly navigator: RouteNavigator,
    private readonly notifications: Notifications
  ) {
  }

  otpMode = false;

  ngOnInit(): void {
    const authenticatedUser = this.authenticationService.getCurrentAuthenticatedUser();
    if (!!authenticatedUser) {
      this.navigator.navigateTo(NavigationRoute.HOME);
    }
  }

  clearNotifications() {
    this.notifications.clearAll();
  }

  otpModeStep(userCredentials: UserCredentialsFormModel) {
    this.otpMode = true;
    this.userCredentials = userCredentials;
  }

  userAuthenticated(authenticatedUser: AuthenticatedUser) {
    this.authenticationService.setCurrentAuthenticatedUser(authenticatedUser);
    this.navigator.navigateTo(NavigationRoute.HOME);
  }

  notifyError(errorResponse: ErrorResponse) {
    const message = singleCodeFromResponse(errorResponse) === ErrorCode.AUTH_INVALID_CREDENTIALS
      ? localizationKey("loginFailedInvalidCredentials")
      : singleCodeFromResponse(errorResponse) === ErrorCode.INVALID_OTP
        ? localizationKey("loginFormInvalidOtp")
        : localizationKey("loginFailed");

    this.notifications.addNotification(NotificationType.ERROR, message);
  }

  reloadLogin() {
    this.otpMode = false;
  }
}
