import {MeasurementStatus} from "../list/found-measurement";
import {MeasurementType} from "../measurement-models";
import {PatientData} from "../upload/upload-model";
import {UploadFormConfig} from "../upload/upload-form-config";
import {emptyUploadFormModel} from "../upload/upload-form-model";


export interface Analysis {
  readonly id: number;
  readonly uniqueId: string;
  readonly code: string;
  readonly uploadTime: string;
  readonly type: MeasurementType;
  readonly status: MeasurementStatus;
  readonly patientData: PatientData;
  readonly doctor: Doctor;
  readonly durationDays?: number;
  readonly startTime?: string;
  readonly doctorFindings: DoctorFindings;
  readonly ecgSamplesDocument?: EcgSamplesDocument;
  readonly externalAnalysisUrl?: string;
  readonly isExternallyAnalyzed?: boolean;
  commentsCount?: number;
  readonly hospitalName: string;
  readonly analysisGenerationConfig: AnalysisGenerationConfig;
  readonly isChain: boolean;
}

export interface EcgSamplesDocument {
  readonly fileName: string;
}

export interface BpFindingsModel {
  avgSystolicBP: string[];
  avgDiastolicBP: string[];
  avgSystolicRange: string[];
  avgDiastolicRange: string[];
  systolicBPload: string[];
  diastolicBPload: string[];
  avgPuls: string[];
}

export interface DoctorFindings {
  findings?: string;
  conclusion?: string;
  patientStatusComments?: string;
  symptomFindings: string[];
  diagnosis?: string;
}

export interface Doctor {
  firstName: string;
  lastName: string;
  title?: string;
  city?: string;
}

export interface AnalysisGenerationConfig {
  findings_prevailingRhythm_sinus?: string;
  findings_prevailingRhythm_afib?: string;
  findings_prevailingRhythm_unknown?: string;

  findings_averageHeartRate?: string;
  findings_minimumHeartRate?: string;
  findings_maximumHeartRate?: string;

  findings_pausesOver2_5s?: string;
  findings_noPausesOver2_5s?: string;

  findings_ventricularBeats?: string;
  findings_oneVentricularBeat?: string;
  findings_noVentricularBeats?: string;

  findings_oneVentricularTachycardia?: string;
  findings_oneVentricularTachycardiaWithLongestDurationGreaterThan10?: string;
  findings_someVentricularTachycardiasWithLongestDurationNotGreaterThan10?: string;
  findings_someVentricularTachycardiasWithLongestDurationGreaterThan10?: string;
  findings_ventricularTachycardiasMaxHrDurationNotGreaterThan10?: string;
  findings_ventricularTachycardiasMaxHrDurationGreaterThan10?: string;
  findings_someVentricularTachycardiasWithLongestDurationGreaterThan10_old?: string;
  findings_ventricularTachycardiasMaxHrEqualsLongestDurationMaxHr?: string;

  findings_atrialBeats?: string;
  findings_oneAtrialBeat?: string;
  findings_noAtrialBeats?: string;
  findings_noAtrialFibrillation?: string;
  findings_someAtrialFibrillation?: string;

  findings_noAtrialTachycardias?: string;
  findings_oneAtrialTachycardia?: string;
  findings_oneAtrialTachycardiaWithLongestDurationLessOrEqualThan10?: string;
  findings_oneAtrialTachycardiaWithLongestDurationGreaterThan10?: string;
  findings_atrialTachycardias?: string;
  findings_atrialTachycardiasLongestBeatsMoreThan10?: string;
  findings_atrialTachycardiasMaxHrDurationNotGreaterThan10?: string;
  findings_atrialTachycardiasMaxHrDurationGreaterThan10?: string;
  findings_atrialTachycardiasLongestDurationGreaterThan10?: string;
  findings_atrialTachycardiasMaxHrEqualsLongestDurationMaxHr?: string;
  findings_avConduction_normal?: string;
  findings_deltaWave_normal?: string;
  findings_qrsWidth_normal?: string;
  findings_qtTime_normal?: string;
  findings_stSegment_normal?: string;

  findings_averageHeartRate_value?: string;
  findings_minimumHeartRate_value?: string;
  findings_maximumHeartRate_value?: string;
  findings_pausesOver2_5s_value?: string;
  findings_ventricularBeats_value?: string;
  findings_ventricularTachycardias_value?: string;
  findings_atrialBeats_value?: string;
  findings_atrialTachycardias_value?: string;

  patientStatusComments_reasonForStudy_arrhythmiaSensations?: string;
  patientStatusComments_reasonForStudy_other?: string;
  patientStatusComments_rhythmAffectingMedication_yes?: string;
  patientStatusComments_rhythmAffectingMedication_no?: string;
  patientStatusComments_symptomsDuringRecording_yes?: string;
  patientStatusComments_symptomsDuringRecording_no?: string;
  patientStatusComments_symptomsDuringRecording_noDiary?: string;

  diagnosis_i49_1?: string;
  diagnosis_i47_1?: string;
  diagnosis_i48_0?: string;
  diagnosis_i48_1?: string;
  diagnosis_i48_2?: string;
  diagnosis_i48_3?: string;
  diagnosis_i48_4?: string;
  diagnosis_i49_5?: string;
  diagnosis_z03_5?: string;
  diagnosis_i49_3?: string;
  diagnosis_i47_2?: string;
  diagnosis_i44_0?: string;
  diagnosis_i44_1?: string;
  diagnosis_i44_2?: string;
  diagnosis_i44_7?: string;
  diagnosis_i45_0?: string;
}

export function createUploadFormConfig(analysis: Analysis): UploadFormConfig {
  return new UploadFormConfig({
    ...emptyUploadFormModel(),
    ...analysis.patientData,
  });
}

export function emptyDoctorFindings(): DoctorFindings {
  return {symptomFindings: []};
}


