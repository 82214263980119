import {Inject, Injectable, InjectionToken} from "@angular/core";
import {interval, Subscription} from "rxjs";

export const TICK_INTERVAL_MS = new InjectionToken<number>("tickIntervalMs");

export interface TickListener {
  readonly tick: () => void;
}


@Injectable()
export class Ticker {

  protected readonly subscriptions: Map<string, Subscription> = new Map;

  constructor(@Inject(TICK_INTERVAL_MS) private readonly tickIntervalMs: number) {
  }

  registerForTicking(name: string, tickListener: TickListener) {
    if(this.subscriptions.has(name)){
      this.subscriptions.get(name)?.unsubscribe();
    }

    this.subscriptions.set(name,
      interval(this.tickIntervalMs)
        .subscribe(() => {
          tickListener.tick();
        })
    );
  }
}
