import {Component, OnInit} from "@angular/core";
import {EditHospitalService} from "./edit-hospital.service";
import {HospitalDetails, OperatorHospital} from "./hospital.details";
import {ActivatedRoute} from "@angular/router";
import {Notifications, NotificationType} from "../../../tools/notifications/notifications";
import {localizationKey} from "../../../i18n/i18n-model";
import {HospitalIdProvider} from "./hospital-id-provider";
import {getMeasurementTypeLocalizationKey, MeasurementType} from "../../measurement-models";
import {FormConfigBase} from "../../../tools/form/form-config-base";
import {HospitalInformation} from "./hospital-information";
import {PropertyChangeListener} from "../../../tools/form/form-field-config";
import {HospitalInformationValidator} from "./hospital-information.validator";
import {languageSelectionOptions} from "../../../tools/languages";
import {ReleaseToggleState} from "../../../tools/release-toggles/release-toggle-state";
import {NurseSettingsSectionState} from "./nurse-settings-section/nurse-settings-section.state";
import {AllowedTypesSectionState} from "./allowed-types-section/allowed-types-section.state";
import {HospitalMeasurementTypesService} from "./hospital-measurement-types.service";


@Component({
  selector: "app-edit-hospital",
  templateUrl: "./edit-hospital.component.html"
})
export class EditHospitalComponent implements OnInit, PropertyChangeListener<HospitalInformation> {
  hospitalDetails?: HospitalDetails;
  eligibleOperators: (OperatorHospital | undefined)[] = [];
  selectedEligibleOperator?: number;

  protected readonly localizationKey = localizationKey;

  protected readonly getMeasurementTypeLocalizationKey = getMeasurementTypeLocalizationKey;

  private hospitalId?: number;

  hospitalDetailsFormConfig?: HospitalDetailsFormConfig;

  private hospitalInformationValidator?: HospitalInformationValidator;

  readonly languageSelectionOptions = languageSelectionOptions;

  constructor(private readonly editHospitalService: EditHospitalService,
              private readonly route: ActivatedRoute,
              private readonly hospitalIdProvider: HospitalIdProvider,
              private readonly notifications: Notifications,
              private readonly hospitalMeasurementTypesService: HospitalMeasurementTypesService,
              private readonly nurseSettingsSectionState: NurseSettingsSectionState,
              private readonly allowedTypesSectionState: AllowedTypesSectionState
  ) {
  }

  ngOnInit(): void {
    this.hospitalId = this.hospitalIdProvider.getId(this.route);

    if (this.hospitalId === undefined) {
      return;
    }

    this.getHospitalDetails();
  }

  onPropertyChange(property: keyof HospitalInformation, newValue: any): void {
    this.hospitalDetails!.hospitalInformation[property] = newValue;
    this.hospitalInformationValidator!.updatePropertyValidation(property);
  }

  addOperator() {
    this.editHospitalService.addOperator(this.hospitalDetails!.id, this.selectedEligibleOperator!)
      .subscribe(() => this.getHospitalDetails(),
        () => this.notifications.addNotification(NotificationType.ERROR, localizationKey("editHospitalFailedToAddOperator")));
  }

  removeOperator(operatorHospital: OperatorHospital) {
    this.editHospitalService.removeOperator(this.hospitalDetails!.id, operatorHospital.id)
      .subscribe(() => this.getHospitalDetails(),
        // () => this.notifications.addNotification(NotificationType.ERROR, localizationKey("editHospitalFailedToAddOperator"))
      );
  }

  private getHospitalDetails() {
    this.selectedEligibleOperator = undefined;
    this.editHospitalService.getHospital(this.hospitalId!)
      .subscribe((hospital) => {
        this.hospitalDetails = hospital;
        this.nurseSettingsSectionState.nurseSettings = {
          hospitalId: hospital.id,
          nurse2Fa: hospital.nurse2Fa,
          reportDeliveryNotification: hospital.nurseReportDeliveryNotification
        };
        this.hospitalInformationValidator = new HospitalInformationValidator(this.hospitalDetails.hospitalInformation);
        this.hospitalDetailsFormConfig = new HospitalDetailsFormConfig(
          this.hospitalDetails.hospitalInformation,
          this,
          (property) => this.hospitalInformationValidator!.isPropertyValid(property)
        );
        this.allowedTypesSectionState.allowedHospitalMeasurementTypes = hospital.allowedMeasurementTypes;
        this.allowedTypesSectionState.hospitalId = hospital.id;
        this.allowedTypesSectionState.hospitalName = hospital.name;
        this.eligibleOperators = [undefined, ...hospital.eligibleOperators];
      }, () => this.notifications.addNotification(NotificationType.ERROR, localizationKey("editHospitalFailedToLoadHospitalDetails")));
  }

  submitHospitalInformation() {
    this.hospitalInformationValidator!.updateAllValidations();

    if (!this.hospitalInformationValidator!.isFormValid()) {
      return;
    }

    this.editHospitalService.updateHospitalInformation({
      ...this.hospitalDetails!.hospitalInformation,
      hospitalId: this.hospitalId!
    })
      .subscribe(
        () => this.notifications.addNotification(NotificationType.OK, localizationKey("hospitalInformationUpdateSuccess")),
        () => this.notifications.addNotification(NotificationType.ERROR, localizationKey("hospitalInformationUpdateError"))
      );
  }

  isHospitalInformationButtonDisabled() {
    return !this.hospitalInformationValidator!.isFormValid();
  }

  getPatientFormTemplateSelectId(measurementType: MeasurementType) {
    return measurementType + "-patient-form-template-select";
  }

  submitHospitalPatientFormTemplates() {
    this.editHospitalService.updateHospitalPatientFormTemplates({
      hospitalId: this.hospitalId!,
      selectedPatientFormTemplates: this.hospitalDetails!.selectedPatientFormTemplates
    })
      .subscribe(
        () => this.notifications.addNotification(NotificationType.OK, localizationKey("hospitalPatientFormTemplatesSuccess")),
        () => this.notifications.addNotification(NotificationType.ERROR, localizationKey("hospitalPatientFormTemplatesError"))
      );
  }

  handleLanguageChange(newLanguage: string) {
    this.hospitalDetails!.language = newLanguage;
  }

  submitHospitalLanguageSettings() {
    this.editHospitalService.updateLanguageSettings(this.hospitalDetails!.id, {
      language: this.hospitalDetails!.language,
      reportLanguageEnabled: this.hospitalDetails!.reportLanguageEnabled
    })
      .subscribe(
        () => this.notifications.addNotification(NotificationType.OK, localizationKey("hospitalLanguageSettingsUpdateSuccess")),
        () => this.notifications.addNotification(NotificationType.ERROR, localizationKey("hospitalLanguageSettingsUpdateFailure"))
      );
  }

  putHospitalInChain280Released(): boolean {
    return ReleaseToggleState
      .getInstance()
      .isReleased("PUT_HOSPITAL_IN_CHAIN_280");
  }
}

class HospitalDetailsFormConfig extends FormConfigBase<HospitalInformation> {
  constructor(
    model: HospitalInformation,
    propertyChangeListener?: PropertyChangeListener<HospitalInformation>,
    validationProvider?: (property: keyof HospitalInformation) => boolean | undefined,
  ) {
    super(model, propertyChangeListener, validationProvider);
  }

  readonly publicName = this.createField({
    property: "publicName",
    type: "text",
    label: "hospitalInformationPublicName",
    invalidLabel: "hospitalInformationPublicNameError"
  });

  readonly operatorAppointmentDescription = this.createField({
    property: "operatorAppointmentDescription",
    type: "text",
    label: "hospitalInformationOperatorAppointmentDescription",
    invalidLabel: "hospitalInformationOperatorAppointmentDescriptionError"
  });

  readonly address = this.createField({
    property: "address",
    type: "text",
    label: "hospitalInformationAddress",
    invalidLabel: "hospitalInformationAddressError"
  });

  readonly postalCode = this.createField({
    property: "postalCode",
    type: "text",
    label: "hospitalInformationPostalCode",
    invalidLabel: "hospitalInformationPostalCodeError"
  });

  readonly city = this.createField({
    property: "city",
    type: "text",
    label: "hospitalInformationCity",
    invalidLabel: "hospitalInformationCityError"
  });

}

