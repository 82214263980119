import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { NavigationRoute } from "./navigation-route";

@Injectable()
export class RouteNavigator {
  constructor(private readonly router: Router) {}

  navigateToPath(pathParts: (NavigationRoute | string)[], queryParams: any = {}) {
    this.router.navigate(pathParts, {queryParams: queryParams});
  }

  navigateTo(route: NavigationRoute, queryParams: any = {}) {
    this.router.navigate([`/${route}`], {queryParams: queryParams});
  }
}
