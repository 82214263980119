import {Injectable} from "@angular/core";
import {ApiClient} from "../../tools/api-client";
import {Observable} from "rxjs";
import {OperatorHospital} from "./operator-hospital";

@Injectable()
export class OperatorHospitalsService {

  constructor(private apiClient: ApiClient) {
  }

  getOperatorHospitals(): Observable<OperatorHospital[]> {
    return this.apiClient.get<OperatorHospital[]>("/api/hospitals/operator");
  }

}
