import {Component, OnInit} from "@angular/core";
import {Notifications, NotificationType} from "../../../../tools/notifications/notifications";
import {localizationKey} from "../../../../i18n/i18n-model";
import {NurseSettingsSectionState} from "./nurse-settings-section.state";
import {EditHospitalService} from "../edit-hospital.service";


export type ReportDeliveryNotification = "NONE" | "INITIATING_NURSE" | "ALL_HOSPITAL_NURSES";


@Component({
  selector: "app-nurse-settings-section",
  templateUrl: "./nurse-settings-section.component.html",
})
export class NurseSettingsSectionComponent implements OnInit {


  constructor(readonly nurseSettingsSectionStateService: NurseSettingsSectionState,
              readonly editHospitalService: EditHospitalService,
              readonly notifications: Notifications) {
  }

  ngOnInit(): void {
  }

  protected readonly localizationKey = localizationKey;
  reportNotificationSetting: ReportDeliveryNotification = "NONE";

  submitNurseSettings() {
    this.editHospitalService.updateNurseSettings(
      this.nurseSettingsSectionStateService.nurseSettings!
    ).subscribe(
      () => this.notifications.addNotification(NotificationType.OK, localizationKey("hospitalNurseSettingsUpdateSuccess")),
      () => this.notifications.addNotification(NotificationType.ERROR, localizationKey("hospitalNurseSettingsUpdateError"))
    );
  }
}
