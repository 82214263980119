import {Component, OnInit, TemplateRef, ViewChild} from "@angular/core";
import {ActivatedRoute} from "@angular/router";
import {BehaviorSubject, Observable, of, Subject} from "rxjs";
import {catchError, finalize, flatMap} from "rxjs/operators";
import {localizationKey, LocalizationStrings} from "../../../i18n/i18n-model";
import {formatDateTime} from "../../../tools/date-time-utils";
import {PropertyChangeListener} from "../../../tools/form/form-field-config";
import {NavigationRoute} from "../../../tools/navigation/navigation-route";
import {RouteNavigator} from "../../../tools/navigation/route-navigator.service";
import {Notifications, NotificationType} from "../../../tools/notifications/notifications";
import {EcgSymptom} from "../../upload/upload-model";
import {UploadFormConfig} from "../../upload/upload-form-config";
import {Analysis, createUploadFormConfig, DoctorFindings, EcgSamplesDocument, emptyDoctorFindings} from "../analysis";
import {AnalysisService} from "../analysis.service";
import {EcgFindingsFormConfig, EcgSymptomFinding, EcgSymptomFindingsFormConfig} from "./ecg-findings-form-config";
import {
  EcgFindingsValidationResult,
  EcgFindingsValidator,
  emptyEcgFindingsValidationResult as emptyFindingsValidationResult,
  initialEcgFindingsValidationResult,
} from "./ecg-findings-validator";
import {FinishAnalysisService} from "../finish-analysis.service";
import {MeasurementIdProvider} from "../measurement-id-provider";
import {EcgReportGenerator, GeneratedReports} from "../ecg-report/ecg-report-generator.service";
import {AuthenticationService} from "../../authentication/authentication.service";
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import {getCommentButtonLabel} from "../../comments/comments.utils";
import {CommentsCountChangedEvent} from "../../comments/comments.component";
import {getFirstSelectedFile} from "../../../tools/form/get-first-selected.file";
import {fromPromise} from "rxjs/internal/observable/innerFrom";
import {ReleaseToggleState} from "../../../tools/release-toggles/release-toggle-state";
import {getSamplesReport} from "./get-samples.report";
import {ErrorCode, ErrorResponse, singleCodeFromResponse} from "../../../tools/error-response";
import {ActionButtonState} from "../../../tools/confirm-action-button/pre-confirm-action-button.component";

@Component({
  selector: "app-symptom-ecg-analysis",
  templateUrl: "./ecg-analysis.component.html",
})
export class EcgAnalysisComponent implements OnInit, PropertyChangeListener<DoctorFindings> {

  readonly labelStudyId: keyof LocalizationStrings = "uploadFormLabelCode";
  readonly labelUniqueId: keyof LocalizationStrings = "reportUniqueIdLabel";
  readonly labelSave: keyof LocalizationStrings = "analysisButtonSave";
  readonly labelInProgress: keyof LocalizationStrings = "analysisLabelSaveInProgress";
  readonly labelSamplesDocument: keyof LocalizationStrings = "analysisLabelEcgSamplesDocument";
  readonly labelStart: keyof LocalizationStrings = "analysisLabelStartTime";
  readonly labelPreviewEcgReport: keyof LocalizationStrings = "analysisButtonPreviewReport";
  readonly labelButtonFinish: keyof LocalizationStrings = "analysisLabelButtonFinish";
  readonly labelButtonFinishConfirm: keyof LocalizationStrings = "analysisLabelButtonFinishConfirm";
  readonly labelButtonFinishCancel: keyof LocalizationStrings = "analysisLabelButtonFinishCancel";
  readonly labelButtonFinishInProgress: keyof LocalizationStrings = "analysisLabelButtonFinishInProgress";
  readonly labelExternalAnalysisUrl: keyof LocalizationStrings = "analysisLabelExternalAnalysisUrl";


  readonly measurementLoadingInProgress = new BehaviorSubject<boolean>(true);
  readonly measurementLoadingInProgress$: Observable<boolean> = this.measurementLoadingInProgress.asObservable();
  readonly saveInProgress = new Subject<boolean>();
  readonly saveInProgress$: Observable<boolean> = this.saveInProgress.asObservable();
  readonly finishAnalysisInProgress = new Subject<boolean>();
  readonly finishAnalysisInProgress$: Observable<boolean> = this.finishAnalysisInProgress.asObservable();
  protected readonly getCommentButtonLabel = getCommentButtonLabel;
  @ViewChild("templateComments") templateComments!: TemplateRef<any>;
  previewInProgress = false;

  modalRefComments: BsModalRef | undefined;

  ecgAnalysis?: Analysis;
  private newEcgSamplesFile?: File;
  foundEcgSamplesDocument?: EcgSamplesDocument;
  isPreviewMode = false;

  ecgFindingsFormConfig: EcgFindingsFormConfig = new EcgFindingsFormConfig(emptyDoctorFindings(), this);

  patientDataFields?: UploadFormConfig;
  ecgSymptomAnalyses: EcgSymptomAnalysis[] = [];
  isFinishMode = false;
  private ecgFindingsValidationResult: EcgFindingsValidationResult = emptyFindingsValidationResult();
  errorMessage?: keyof LocalizationStrings;

  private readonly onFindingChanged = (symptomIndex: number, finding: string) => {
    this.ecgAnalysis!.doctorFindings.symptomFindings[symptomIndex] = finding;
    this.validateFindings();
  };

  constructor(
    private readonly measurementIdProvider: MeasurementIdProvider,
    private readonly analysisService: AnalysisService,
    private readonly notifications: Notifications,
    private readonly route: ActivatedRoute,
    private readonly ecgReportGenerator: EcgReportGenerator,
    private readonly finishAnalysisService: FinishAnalysisService,
    private readonly navigator: RouteNavigator,
    private readonly findingsValidator: EcgFindingsValidator,
    readonly authenticationService: AuthenticationService,
    readonly modalService: BsModalService,
  ) {
    const user = authenticationService.getCurrentAuthenticatedUser();

    this.isPreviewMode = user?.role === "DATA_OFFICER";
  }

  ngOnInit(): void {
    const measurementId = this.measurementIdProvider.getId(this.route);

    if (measurementId === undefined) {
      return;
    }

    this.loadAnalysis(measurementId);
  }

  private loadAnalysis(measurementId: number): void {
    this.analysisService
      .getAnalysis(measurementId)
      .pipe(finalize(() => this.measurementLoadingInProgress.next(false)))
      .subscribe(
        (ecgAnalysis) => this.updateAnalysis(ecgAnalysis),
        () => this.notifications.addNotification(NotificationType.ERROR, localizationKey("analysisMessageFailedGet"))
      );
  }

  private updateAnalysis(analysis: Analysis): void {
    this.ecgAnalysis = analysis;
    this.foundEcgSamplesDocument = analysis.ecgSamplesDocument;
    this.patientDataFields = createUploadFormConfig(analysis);

    this.ecgFindingsFormConfig = new EcgFindingsFormConfig(
      analysis.doctorFindings,
      this,
      (property) => this.ecgFindingsValidationResult[property].simpleResult
    );

    const symptoms = this.ecgAnalysis.patientData.symptoms;
    if (analysis.doctorFindings.symptomFindings.length === 0) {
      analysis.doctorFindings.symptomFindings = symptoms.map((_) => "");
    }

    this.ecgFindingsValidationResult = initialEcgFindingsValidationResult(analysis.doctorFindings);

    this.ecgSymptomAnalyses = createAnalyses(
      this.ecgAnalysis.patientData.symptoms,
      analysis,
      this.onFindingChanged,
      (symptomIndex) => this.ecgFindingsValidationResult.symptomFindings.arrayResult[symptomIndex]
    );

  }

  isEcgHolterOrAtrialFibrillation(): boolean {
    return this.ecgAnalysis?.type === "HOLTER_ECG" || this.ecgAnalysis?.type === "ECG_ATRIAL_FIBRILLATION";
  }

  onEcgSamplesFileSelected(file?: File) {
    this.newEcgSamplesFile = file;
    this.validateFindings();
  }

  clearExistingEcgSamplesFile() {
    this.foundEcgSamplesDocument = undefined;
    this.validateFindings();
  }

  onSaveButtonClick(): void {
    if (!this.validateEcgFindingsForm()) {
      return;
    }

    this.saveInProgress.next(true);
    this.analysisService
      .saveAnalysis(this.ecgAnalysis!.id, this.ecgAnalysis!.doctorFindings, this.newEcgSamplesFile)
      .pipe(finalize(() => this.saveInProgress.next(false)))
      .subscribe(
        (savedEcgAnalysis) => {
          this.notifications.addNotification(NotificationType.OK, localizationKey("analysisMessageSaveSuccess"));
          this.ecgAnalysis = savedEcgAnalysis;
          this.foundEcgSamplesDocument = savedEcgAnalysis.ecgSamplesDocument;
        },
        () => (this.errorMessage = "analysisMessageSaveFailed")
      );
  }

  onPropertyChange(property: keyof DoctorFindings, newValue: any): void {
    this.ecgAnalysis!.doctorFindings[property] = newValue;
    this.validateFindings();
  }

  previewEcgReport(): void {
    this.previewInProgress = true;
    this.ecgReportGenerator.generateAndPreviewReport(
      this.ecgAnalysis!,
      this.getSamplesReport(false)
        .pipe(finalize(() => this.previewInProgress = false))
    );
  }

  private getSamplesReport(isFinish: boolean) {
    return getSamplesReport(
      {
        fileSelected: this.newEcgSamplesFile !== undefined,
        fileUploadedBefore: this.ecgAnalysis!.ecgSamplesDocument !== undefined,
        isExternal: !!this.ecgAnalysis!.isExternallyAnalyzed,
        getSamplesFromBackend: () => this.downloadSamplesReport(isFinish).pipe(catchError(_ => of(undefined))),
        readFile: () => this.readSamplesFile()
      }
    );
  }

  private readSamplesFile(): Observable<string | undefined> {
    return fromPromise(new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve((reader.result! as string).split(",")[1]);
      reader.onerror = error => reject(error);
      reader.readAsDataURL(this.newEcgSamplesFile!);
    }));
  }

  private downloadSamplesReport(isFinish: boolean): Observable<string | undefined> {
    return this.analysisService
      .getSamplesReport(this.ecgAnalysis!.id, isFinish);
  }

  switchToFinishMode(): void {
    this.isFinishMode = true;
    this.errorMessage = undefined;
    this.validateFindings();
  }

  switchToSaveMode(): void {
    this.isFinishMode = false;
    this.errorMessage = undefined;
    this.validateFindings();
  }

  onFinishAnalysisClick(): void {
    if (!this.validateEcgFindingsForm()) {
      return;
    }

    this.finishAnalysisInProgress.next(true);

    this.analysisService
      .saveAnalysis(this.ecgAnalysis!.id, this.ecgAnalysis!.doctorFindings, this.newEcgSamplesFile)
      .pipe(
        flatMap(() => this
          .ecgReportGenerator
          .generateReports(
            this.ecgAnalysis!,
            this.getSamplesReport(true)
          )
        ),
        flatMap((reports: GeneratedReports) =>
          this
            .finishAnalysisService
            .finishAnalysis(
              this.ecgAnalysis!.id!,
              reports.report
            )
        ),
        finalize(() => this.finishAnalysisInProgress.next(false))
      )
      .subscribe(
        () => this.navigator.navigateTo(NavigationRoute.LIST),
        (errorResponse: ErrorResponse) =>
          (this.errorMessage = singleCodeFromResponse(errorResponse) === "ecgSamplesDocumentMissing" ? "finishAnalysisFailedBecauseSamplesDocumentMissing" : "analysisMessageFinishAnalysisFailed")
      );
  }

  private validateEcgFindingsForm(): boolean {
    this.validateFindings();
    const isValid = this.isFindingsValid() && this.isEcgSamplesFileValid();
    if (!isValid) {
      this.errorMessage = "analysisFormValidationError";
    }

    return isValid;
  }

  private isFindingsValid(): boolean {
    return (Object.keys(this.ecgFindingsValidationResult) as [keyof EcgFindingsValidationResult])
      .map((key) => this.ecgFindingsValidationResult[key])
      .every(
        (validationResult) => validationResult.simpleResult !== false && validationResult.arrayResult.every((result) => result !== false)
      );
  }

  isEcgSamplesFileValid(): boolean {
    return this.ecgFindingsValidationResult.ecgSampleFile.simpleResult !== false;
  }

  private validateFindings(): void {
    this.ecgFindingsValidationResult = this.findingsValidator.validate(
      {
        measurementType: this.ecgAnalysis!.type,
        measurementCode: this.ecgAnalysis!.code,
        ecgFindings: this.ecgAnalysis!.doctorFindings,
        foundEcgSamplesDocument: this.foundEcgSamplesDocument,
        newEcgSamplesFile: this.newEcgSamplesFile,
        ecgSamplesRequired: !this.ecgAnalysis!.isExternallyAnalyzed,
      },
      this.isFinishMode
    );
  }

  onShowCommentsClick() {
    this.modalRefComments = this.modalService.show(this.templateComments, {
      class: "modal-lg"
    });
  }

  onCommentsCountChanged(event: CommentsCountChangedEvent) {
    this.ecgAnalysis!.commentsCount = event.newCommentCount;
  }

  getButtonType() {
    if (this.ecgAnalysis!.commentsCount === 0) {
      return "btn-secondary";
    } else {
      return "btn-primary";
    }
  }

  protected readonly localizationKey = localizationKey;

  setPatientStatusComments(patientStatusComments: string) {
    this.ecgAnalysis!.doctorFindings.patientStatusComments = patientStatusComments;
  }

  setFindings(findings: string) {
    this.ecgAnalysis!.doctorFindings.findings = findings;
  }

  setDiagnosis(diagnosis: string) {
    this.ecgAnalysis!.doctorFindings.diagnosis = diagnosis;
  }

  protected readonly getFirstSelectedFile = getFirstSelectedFile;

  skipReportState: ActionButtonState = "INITIAL";

  isExternallyAnalyzed() {
    return !!this.ecgAnalysis!.isExternallyAnalyzed;
  }

  canSkipReport() {
    return ReleaseToggleState.getInstance().isReleased("SKIP_REPORT_411")
      && this.authenticationService.getCurrentAuthenticatedUser()?.role === "DOCTOR"
      && this.ecgAnalysis?.isChain;
  }

  skipReport() {

    if (this.skipReportState === "INITIAL") {
      this.skipReportState = "CONFIRMATION";
      return;
    }

    this.skipReportState = "ACTION_IN_PROGRESS";
    this
      .finishAnalysisService
      .finishAnalysisWithSkippedReport(this.ecgAnalysis!.id!)
      .pipe(
        finalize(() => {
          this.skipReportState = "INITIAL";
        })
      )
      .subscribe({
        next: () => {
          this.navigator.navigateTo(NavigationRoute.LIST);
        },
        error: (errorResponse: ErrorResponse) => {
          const message = localizationKey(
            singleCodeFromResponse(errorResponse) === ErrorCode.ECG_SAMPLES_DOCUMENT_MISSING
              ? "analysisMessageSkippingReportFailedBecauseSamplesDocumentMissing"
              : "analysisMessageFinishAnalysisFailed"
          );

          this.notifications.addNotification(NotificationType.ERROR, message);
        }
      });
  }
}

interface EcgSymptomAnalysis {
  ecgSymptomDescription: string;
  ecgSymptomFindingFormConfig: EcgSymptomFindingsFormConfig;
}

function createAnalyses(
  ecgSymptoms: EcgSymptom[],
  ecgAnalysis: Analysis,
  findingsChangeListener: (symptomIndex: number, finding: string) => void,
  symptomValidator: (symptomIndex: number) => boolean | undefined
): EcgSymptomAnalysis[] {
  return ecgSymptoms.map((symptom, index) => ({
    ecgSymptomDescription: describeEcgSymptom(symptom),
    ecgSymptomFindingFormConfig: new EcgSymptomFindingsFormConfig(
      {finding: ecgAnalysis.doctorFindings.symptomFindings[index]},
      {
        onPropertyChange(property: keyof EcgSymptomFinding, newValue: any): void {
          findingsChangeListener(index, newValue);
        },
      },
      (_) => symptomValidator(index)
    ),
  }));
}

function describeEcgSymptom(ecgSymptom: EcgSymptom): string {
  return `${formatDateTime(ecgSymptom.time)} ${ecgSymptom.description}`;
}
