import {Injectable} from "@angular/core";
import {ApiClient} from "../../../tools/api-client";
import {Observable} from "rxjs";

@Injectable()
export class ForgotPasswordService {
  constructor(private readonly apiClient: ApiClient) {
  }

  resetForgottenPassword(username: string): Observable<void> {
    return this.apiClient.put(`/api/forgot-password`, {username: username});
  }
}
