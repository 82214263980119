<div class="container row" data-testid="login-page">
  <div class="card col-md-8 mx-auto">
    <app-user-login *ngIf="otpMode===false"
                    data-testid="login-form"
                    (clearNotifications)="clearNotifications()"
                    (otpRequiredChange)="otpModeStep($event)"
                    (userAuthenticated)="userAuthenticated($event)"
                    (notifyError)="notifyError($event)"></app-user-login>

    <app-otp-login *ngIf="otpMode===true"
                   data-testid="otp-input-form"
                   [userCredentials]="userCredentials"
                   (clearNotifications)="clearNotifications()"
                   (userAuthenticated)="userAuthenticated($event)"
                   (reloadLogin)="reloadLogin()"
                   (notifyError)="notifyError($event)"></app-otp-login>
  </div>
</div>
